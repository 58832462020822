<template>
	<div class="m60 wrapper">
		<!-- Header Section -->
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.push({ name: 'Alram' })">
					<div class="top-iconClose">
						<img
							:src="require('@/assets/img/ic_notifications.svg')"
							alt="알림"
						/>
					</div>
				</a>
				<p>서비스</p>
			</div>
			<div class="service-menu">
				<a href="#">
					<p>우정서비스</p>
				</a>
				<a href="#">
					<span>연계서비스</span>
				</a>
			</div>
		</div>
		<!-- End of Header Section -->

		<!-- 서비스 선택하기 -->
		<div class="box-service">
			<!-- 우편 서비스 -->
			<ul class="box-txt">
				<li>
					<div class="service-img">
						<img :src="require('@/assets/img/ic_visual_post.svg')" alt="우편" />
						<p>우편</p>
					</div>
					<a @click="clickListener('post')">
						<span>{{ this.hookState.post ? '조회하기' : '연동하기' }}</span>
					</a>
				</li>
				<div class="box-shadow"></div>
			</ul>
			<!-- 쇼핑 서비스 -->
			<ul class="box-txt">
				<li>
					<div class="service-img">
						<img
							:src="require('@/assets/img/ic_visual_shopping.svg')"
							alt="쇼핑"
						/>
						<p>쇼핑</p>
					</div>
					<a @click="clickListener('shopping')">
						<span>{{ this.hookState.shopping ? '조회하기' : '연동하기' }}</span>
					</a>
				</li>
				<div class="box-shadow"></div>
			</ul>
			<!-- 예금 서비스 -->
			<ul class="box-txt">
				<li>
					<div class="service-img">
						<img
							:src="require('@/assets/img/ic_visual_savings.svg')"
							alt="예금"
						/>
						<p>예금</p>
					</div>
					<a @click="clickListener('bank')">
						<span>{{ this.hookState.bank ? '조회하기' : '연동하기' }}</span>
					</a>
				</li>
				<div class="box-shadow"></div>
			</ul>
			<!-- 펀드 서비스 -->
			<ul class="box-txt">
				<li>
					<div class="service-img">
						<img :src="require('@/assets/img/ic_visual_fund.svg')" alt="펀드" />
						<p>펀드</p>
					</div>
					<a @click="clickListener('fund')">
						<span>{{ this.hookState.fund ? '조회하기' : '연동하기' }}</span>
					</a>
				</li>
				<div class="box-shadow"></div>
			</ul>
			<!-- 보험 서비스 -->
			<ul class="box-txt">
				<li>
					<div class="service-img">
						<img
							:src="require('@/assets/img/ic_visual_insurance.svg')"
							alt="보험"
						/>
						<p>보험</p>
					</div>
					<a @click="clickListener('insurance')">
						<span>{{
							this.hookState.insurance ? '조회하기' : '연동하기'
						}}</span>
					</a>
				</li>
				<div class="box-shadow"></div>
			</ul>
		</div>
		<!-- //서비스 선택하기 -->

		<!-- Navigation Style Code -->
		<Footer :id="1" />

		<!-- 큐알스캔 이미지 -->
		<!-- 큐알스캔 이미지 -->
		<div class="qr-scan modal-event">
			<a @click="openQRScanner">
				<img
					:src="require('@/assets/img/ic_qr (1).svg')"
					alt="큐알스캔 이미지"
				/>
				<p>QR 스캔</p>
			</a>
		</div>

		<CodePassModal v-if="isCodeModalShow" @close="codePassModalCloseListener" />
	</div>
</template>

<script>
import Footer from '@/components/Footer';
import CodePassModal from '@/components/modal/CodePassModal';

import { KPIC_dispatchQrReader } from '@/util/appBridge';

export default {
	name: 'Service',

	components: {
		CodePassModal,
		Footer,
	},

	data() {
		return {
			isCodeModalShow: false,

			hookState: {
				post: true,
				shopping: true,
				bank: true,
				fund: true,
				insurance: true,
			},
		};
	},

	methods: {
		codePassModalCloseListener() {
			this.isCodeModalShow = false;
		},

		clickListener(id) {
			if (id === 'post') {
				if (this.hookState.post) {
					this.$router.push({ name: 'PostList' });
				} else {
					alert(id + ' 준비중입니다.');
				}
			} else if (id === 'shopping') {
				if (this.hookState.shopping) {
					this.$router.push({ name: 'ShoppingList' });
				} else {
					alert(id + ' 준비중입니다.');
				}
			} else if (id === 'bank') {
				if (this.hookState.bank) {
					this.$router.push({ name: 'BankList' });
				} else {
					alert(id + ' 준비중입니다.');
				}
			} else if (id === 'fund') {
				if (this.hookState.fund) {
					this.$router.push({ name: 'FundList' });
				} else {
					alert(id + ' 준비중입니다.');
				}
			} else if (id === 'insurance') {
				if (this.hookState.insurance) {
					this.$router.push({ name: 'InsulanceList' });
				} else {
					alert(id + ' 준비중입니다.');
				}
			}
		},

		openQRScanner() {
			KPIC_dispatchQrReader();
		},
	},

	created() {},
};
</script>
